@import "../../mixins.scss";

.qr-code-container {
  width: 476px;
  margin: 0 auto;
  height: 100vh;

  h2 {
    margin-bottom: 24px;
    font-size: 14px;
    color: #212121;
    line-height: 16px;
    font-weight: 600;
  }

  .top-header {
    padding-top: 16px;
    padding-bottom: 16px;
    @include flex;
    @include justify-content(flex-end);
  }

  .qrcode-section {
    height: calc(100% - 64px);
    @include flex;
    @include justify-content(center);
    flex-direction: column;
    text-align: center;
  }

  .actions {
    margin-top: 24px;
    @include flex;
    @include align-items(center);
    @include justify-content(center);
    flex-direction: row;

    .btn-link {
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
    }
  }

  .ant-btn > .anticon + span, .ant-btn > span + .anticon {
    margin-left: 0;
  }
}