@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;800;900&display=swap');
@import "~antd/dist/antd.css";
@import './mixins.scss';

html {
  font-size: 62.5% !important;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', sans-serif;
  height: 100vh;
  margin: 0;
  font-size: 14px;
  color: $primary-text-color;
  font-weight: 400;
}

a {
	text-decoration: none;
	color: $primary-text-color;

	&:hover {
		text-decoration: none;
		color: $primary-text-color;
	}
}

#admin-container {
	header {
    padding: 24px;

		@include flex;
    @include justify-content(space-between);
    @include align-items(center);
	}

	.admin-main-container {
		@include flex;

		height: calc(100vh - 121px);
	}

	.menu-container {
		min-width: 160px;

		ul {
			padding-left: 24px;
		}
	}

	.content-container {
		width: 100%;
		padding: 24px;
	}
}

.page-header {
	margin-bottom: 24px;
	@include flex;
	@include justify-content(space-between);
	@include align-items(center);

	.page-header-title {
		font-size: 16px;
		line-height: 20px;
		font-weight: 400;
	}
}

.page-header-details-actions {
	margin-bottom: 24px;
	@include flex;
	@include justify-content(space-between);
	@include align-items(center);
}

.page-header-breadbrumb {
	margin-bottom: 24px;
	@include flex;
	@include justify-content(space-between);
	@include align-items(center);

	ul {
		list-style: none;
    padding-left: 0px;
		margin-bottom: 0;

		li, a {
			text-transform: uppercase;
			font-size: 14px;
			line-height: 20px;
			font-weight: 600;
		}
	}

	.ant-breadcrumb {
		@include flex;
		@include align-items(center);
	}

	.ant-breadcrumb-link {
		a {
			text-transform: uppercase;
			font-size: 14px;
			line-height: 20px;
			font-weight: 600;
		}
	}
}

.btn-table-action-primary {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: #1E88E5;
}

.btn-table-action-danger {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: #F44336;
}

.btn {
	font-size: 12px;
	padding: 8px 20px;
	border-radius: 4px;
	line-height: 16px;
	text-transform: uppercase;
	font-weight: 600;

	@include flex;
	@include align-items(center);

	div {
		height: 19px;
	}

	svg {
		width: 16px;
		height: 16px;
		margin-left: 8px;
	}
}

.btn-primary {
	padding: 10px 24px;
  color: #fff;
  border: 1px solid $primary-red-color;
  background-color: $primary-red-color;

  @include flex;
  @include align-items(center);
	@include border-radius(4px);

  & > div {
    margin-left: 10px;
		height: 19px;
  }

	svg {
		width: 16px;
		height: 16px;

		path{
			fill: #fff;
		}
	}

	&:hover {
		border: 1px solid $primary-red-color;
  	background-color: $primary-red-color;
		opacity: 0.8;
	}
}

.btn-secondary {
  border: 1px solid $primary-red-color;
  color: $primary-red-color;

  @include flex;
  @include align-items(center);
  @include border-radius(4px);

  & > div {
    margin-left: 10px;
  }
}

.btn-danger {
  color: #F44336;
  border: 1px solid #F44336;

  @include flex;
  @include align-items(center);
  @include border-radius(4px);

	& > div {
    margin-left: 10px;
		height: 16px;
  }

	svg {
		margin-left: 0;
		width: 14px;
		height: 14px;

		path{
			fill: #F44336;
		}
	}

	&:hover {
		border: 1px solid #F44336;
		opacity: 0.8;
		color: #F44336;
	}
}

.btn-back {
	padding: 10px 24px;
  color: #888;
	font-size: 14px;
	border: 1px solid #888;

  @include flex;
  @include align-items(center);
  @include border-radius(4px);

  & > div {
    margin-right: 10px;
		height: 15px;
  }

	svg {
		margin-left: 0;
		width: 14px;
		height: 14px;

		path{
			fill: #888;
		}
	}

	&:hover {
		border: 1px solid #888;
		opacity: 0.8;
		color: #888;
	}
}

.error-container {
	text-align: center;
}

.error-message {
	font-size: 12px;
	color: $danger-color;
	line-height: 16px;
	font-weight: 400;
}

.form-page-container {
	.form-page-header {
		h2.form-page-header-title {
			font-weight: 600;
    	font-size: 14px;
    	text-transform: uppercase;
			position: relative;
			line-height: 30px;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: -20px;
				width: 4px;
				height: 100%;
				background-color: $primary-red-color;

				@include border-top-right-radius(0.2rem);
      	@include border-bottom-right-radius(0.2rem);
			}
		}
	}

	.form-page-box {
		width: 100%;
		padding: 20px;
    border: 1px solid #e6e6e6;
    border-radius: .4rem;
	}

	.form-page-content {
		margin-top: 24px;
	}
}

.form-actions {
	@include flex;
	@include justify-content(flex-end);

	button, a {
		&:first-child {
			margin-right: 20px;
		}
	}
}

.input-group {
	margin-bottom: 20px;

  &:last-child {
		margin-bottom: 0;
	}

  label {
		display: block;
    font-size: 14px;
    color: $primary-text-color;
    font-weight: 600;
    margin-bottom: 0.4rem;
  }

  .input-field {
    position: relative;
    width: 100%;

    > input {
      position: relative;
      width: 100%;
      height: 4rem;
      border: none;
      background-color: #f4f4f4;
      font-size: 1.4rem;
      text-indent: 1rem;
      color: $primary-text-color;

      @include border-radius(0.4rem);

      &:focus {
        outline: none;
      }
    }
  }

  select.select-field {
    width: 100%;
    font-size: 1.4rem;
    background-position-x: 98%;
    color: $text-placeholder;
  }
}

.ant-form-horizontal .ant-form-item-label {
	min-width: 130px;
	text-align: left;
}

.ant-form-item-label > label::after {
	display: none;
}

.ant-row.ant-form-item {
	flex-flow: row nowrap;
}

.ant-modal-body {
	padding: 36px 24px;
}

.ant-input-number {
	width: 100%;
}

table {
	tr td, tr th {
		&:last-child {
			border-left: 1px solid #f0f0f0;
		}

		.action {
			@include justify-content(flex-end);
		}
	}
}

.box {
	background-color: #fff;
	padding: 16px;
	
	@include border-radius(4px);
	@include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.08));

	.box-header {
		h2.box-title {
			margin-bottom: 24px;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 30px;
			position: relative;

			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: -16px;
				width: 4px;
				height: 100%;
				background-color: $primary-red-color;

				@include border-top-right-radius(0.2rem);
      	@include border-bottom-right-radius(0.2rem);
			}
		}
	}
}

.details-item {
	margin-bottom: 20px;
	@include flex;

	label {
		min-width: 156px;
		margin-right: 4px;
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px;
		color: $primary-text-color;
	}

	.text {
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		color: $primary-text-color;
	}
}

.answers-container > .answer-item{
	display: block;
	margin-bottom: 16px;
}

.ant-table-row {
	cursor: pointer;
}

.ant-spin-dot-item {
	background-color: $primary-red-color;
}

.ant-spin-text {
	color: $primary-red-color;
}

.ant-form-item-explain.ant-form-item-explain-error {
	color: #ff4d4f;
	// position: absolute;
	// left: 0;
	// bottom: -24px;
	font-size: 12px;
	line-height: 16px;
}

.add-mobile-version-modal {
	.ant-form-item-explain.ant-form-item-explain-error {
		position: relative;
		bottom: 0;
	}
}

.ant-layout {
	height: 100%;
	background: #f0f2f5;
	overflow: scroll;
	margin-bottom: 80px;
}

@media (max-width: 575px) {
	.details-item {
		flex-flow: row wrap;
	}
}