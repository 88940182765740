@import "../../mixins.scss";

#session-container {
  height: 100vh;
	padding: 0 16px;

  @include flex;
  @include justify-content(center);
  @include align-items(center);

	.login-page {
		width: 400px;
		padding: 0 15px;
	}

	.forgot-password-title {
		margin-bottom: 24px;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	}

	.ant-form-item {
		display: block;
	}

	.ant-form-item-label {
		label {
			font-size: 12px;
			line-height: 16px;
			font-weight: 600;
		}
	}

	.forgot-password-page {
		max-width: 360px;
		width: 100%;

		.btn-primary {
			@include justify-content(center);
		}
	}

	.forgot-password-link {
		a {
			color: #1890ff;
		}
	}
}